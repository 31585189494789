<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel main-panel-sign-up">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">

                    <div class="row w-100 mx-0">
                        <div class="col-lg-6 mx-auto">
                            <div class="auth-form-transparent box-padding-sing-up  text-left py-5 px-4 px-sm-5 border">
                                <div class="brand-logo">
                                    <img src="assets/images/IRISGST-logo.png" alt="logo">
                                </div>
                                <form [formGroup]="signupForm" class="pt-3" autocomplete="off">
                                    <h4 class="clrWhite">Sign up</h4>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">Username</label>
                                        <div class="input-group">
                                            <!-- <div class="input-group-prepend bg-transparent">
                                        <span class="input-group-text bg-transparent border-right-0" [ngClass]="{ 'errorBorder': isSubmitted && f.name.errors }">
                                            <i class="material-icons"> account_circle   </i>
                                        </span>
                                    </div> -->
                                            <input type="text" class="form-control form-control-lg "
                                                id="exampleInputEmail" placeholder="Username" formControlName="name"
                                                [ngClass]="{ 'errorBorder': isSubmitted && f.name.errors }"
                                                autocomplete="new-password">
                                        </div>
                                        <div *ngIf="isSubmitted && f.name.errors">
                                            <span class="text-danger" *ngIf="f.name.errors.required"> Username
                                                Required
                                            </span>
                                            <!-- <span class="text-danger" *ngIf="signupForm.get('name').dirty && signupForm.get('name').errors">Invalid
                                        UserName
                                    </span> -->
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword">Email ID</label>
                                        <div class="input-group">
                                            <!-- <div class="input-group-prepend bg-transparent">
                                        <span class="input-group-text bg-transparent border-right-0" [ngClass]="{ 'errorBorder': isSubmitted && f.email.errors }">
                                            <i class="material-icons">
                                                lock
                                            </i>
                                        </span>
                                    </div> -->
                                            <input type="email" class="form-control form-control-lg "
                                                id="exampleInputPassword" placeholder="Email ID" formControlName="email"
                                                [ngClass]="{ 'errorBorder': isSubmitted && f.email.errors }"
                                                autocomplete="new-password">
                                        </div>
                                        <div *ngIf="isSubmitted && f.email.errors">
                                            <span class="text-danger" *ngIf="f.email.errors.required"> Email
                                                Required
                                            </span>
                                            <span class="text-danger" *ngIf="f.email.errors">Invalid
                                                Email
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group form-group-consent-box">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="consentGiven"
                                                formControlName="consentGiven" [ngClass]="{ 'errorBorder': isSubmitted && f.consentGiven.errors }">
                                            <label class="form-check-label form-check-consent-box" style="margin-bottom: 0rem;" for="consentGiven">
                                                In compliance with the Information Technology Act 2000 and related
                                                rules, we seek your consent to collect and use
                                                your personal data (e.g. email ID, mobile number) for authentication,
                                                notifications, and service facilitation on
                                                our platform. By providing your personal data, you agree to its
                                                collection, use, processing, and storage as per our
                                                <a class="clrWhite text-decoration-underline" href="https://irisgst.com/terms-and-condition-irisgst/"
                                                    target="_blank"> Terms & Conditions </a> and <a class="clrWhite text-decoration-underline"
                                                    href="https://irisgst.com/privacy-policy/" target="_blank"> Privacy Policy </a>. Your data will not be disclosed
                                                without your explicit consent unless required
                                                by law.
                                            </label>
                                            <div *ngIf="isSubmitted && f.consentGiven.errors" style="margin-left: -20px;">
                                                <span class="text-danger" *ngIf="f.consentGiven.errors.required"> Consent
                                                    Required
                                                </span>
                                                <!-- <span class="text-danger" *ngIf="signupForm.get('name').dirty && signupForm.get('name').errors">Invalid
                                            UserName
                                        </span> -->
                                            </div>
                                        </div>
                                    </div>
                                    <re-captcha #invisible (resolved)="resolved($event)" size="invisible"
                                        [siteKey]="recaptchaSiteKey"></re-captcha>
                                    <div class="my-3">
                                        <button
                                            class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            (click)="signup()">SIGN
                                            UP
                                        </button>
                                    </div>
                                    <div class="my-2 d-flex justify-content-between align-items-center">
                                        <a (click)="forgotPassword()" class="auth-link clrWhite"> Forgot password?</a>
                                        <a (click)="login()" class="clrWhite"> Sign in</a>
                                    </div>
                                    <!-- <div class="mb-2 d-flex">
                                <button type="button" class="btn btn-google auth-form-btn flex-grow mr-1" (click)="forgotPassword()">
                                  Forgot password?
                                </button>
                                <button type="button" class="btn btn-facebook auth-form-btn flex-grow ml-1" (click)="login()">
                                    Sign in
                                </button>
                            </div> -->
                                    <!-- <div class="text-center mt-4 font-weight-light">
                                              Don't have an account? <a href="register-2.html" class="text-primary">Create</a>
                                            </div> -->
                                    <div class="form-group form-group-info-box clrWhite">
                                       <label class="form-check-label"> International Infotech Park, Vashi, Navi Mumbai </label> | <a
                                       class="clrWhite text-decoration-underline form-check-label" href="https://www.irisgst.com" target="_blank">https://www.irisgst.com</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>