<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
            <div class="row flex-grow main-panel">
                <div class="content-wrapper d-flex  align-items-center justify-content-center auth px-0">
                    <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                            <div class="auth-form-transparent  text-left py-5 px-4 px-sm-5 border">
                                <div class="brand-logo">
                                    <img src="assets/images/IRISGST-logo.png" alt="logo">
                                </div>

                                <form [formGroup]="loginForm" class="pt-3" autocomplete="off">
                                    <h3 class="clrWhite">Sign in</h3>
                                    <div class="form-group">
                                        <label for="exampleInputEmail">Username</label>
                                        <div class="input-group">
                                            <!-- <div class="input-group-prepend bg-transparent">
                                        <span class="input-group-text bg-transparent border-right-0">
                                            <i class="fa fa-user-circle-o material-icons">
                                            </i>
                                        </span>
                                    </div> -->
                                            <input type="text" class="form-control form-control-lg "
                                                formControlName="email" id="exampleInputEmail" placeholder="Username" autocomplete="new-password">
                                        </div>
                                        <div *ngIf="isSubmitted">
                                            <span class="text-danger"
                                                *ngIf="!loginForm.get('email').dirty && loginForm.get('email').errors.required">Email
                                                Required
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="loginForm.get('email').dirty && loginForm.get('email').errors">Invalid
                                                Email
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="exampleInputPassword">Password</label>
                                        <div class="input-group">
                                            <!-- <div class="input-group-prepend bg-transparent">
                                        <span class="input-group-text bg-transparent border-right-0">
                                            <i class="fa fa-lock material-icons">
                                            </i>
                                        </span>
                                    </div> -->
                                            <input type="password" class="form-control form-control-lg "
                                                formControlName="password" id="exampleInputPassword"
                                                placeholder="Password" autocomplete="new-password">
                                        </div>
                                        <div *ngIf="isSubmitted">
                                            <span class="text-danger"
                                                *ngIf="!loginForm.get('password').dirty && loginForm.get('password').errors.required">Password
                                                Required
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="loginForm.get('password').dirty && loginForm.get('password').errors">Invalid
                                                Password
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <re-captcha #invisible (resolved)="resolved($event)" size="invisible" [siteKey]="recaptchaSiteKey"></re-captcha> -->
                                    <div class="my-3">
                                        <button
                                            class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            (click)="login()">SIGN
                                            IN
                                        </button>
                                    </div>
                                    <div class="my-2 d-flex justify-content-between align-items-center">
                                        <a (click)="forgotPassword()" class="auth-link clrWhite"> Forgot password?</a>
                                        <a (click)="signup()" class="clrWhite"> Sign Up</a>
                                    </div>
                                    <div class="my-2 d-flex justify-content-between align-items-center">
                                      <a [routerLink]="'/domain-selection'" class="auth-link clrWhite"> Sign In using SAML</a>
                                    </div>
                                    <!-- <div class="mb-2 d-flex">
                                <button type="button" class="btn btn-google auth-form-btn flex-grow mr-1" (click)="forgotPassword()">
                                   Forgot password?
                                </button>
                                <button type="button" class="btn btn-facebook auth-form-btn flex-grow ml-1" (click)="signup()">
                                    Sign up
                                </button>
                            </div> -->
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- <button type="button" (click)="showConsentModal()" class="btn btn-primary" data-dismiss="modal">Consent</button> -->
<p-dialog [(visible)]="displayConsentModal" [modal]="true" [closable]="true" [draggable]="false" [resizable]="false" header="Consent Box">
    <div class="form-check form-constent-sign-in">
        <input type="checkbox" class="form-check-input" id="consentGiven" [(ngModel)]="isChecked" />
        <label class="form-check-label form-constent-sign-in-label" for="consentGiven">
          In compliance with the Information Technology Act 2000 and related
          rules, we seek your consent to collect and use
          your personal data (e.g. email ID, mobile number) for authentication,
          notifications, and service facilitation on
          our platform. By providing your personal data, you agree to its
          collection, use, processing, and storage as per our
          <a style="color: #0574d5 !important;" class="clrWhite text-decoration-underline" href="https://irisgst.com/terms-and-condition-irisgst/"
              target="_blank"> Terms & Conditions </a> and <a style="color: #0574d5 !important;" class="clrWhite text-decoration-underline"
              href="https://irisgst.com/privacy-policy/" target="_blank"> Privacy Policy </a>. Your data will not be disclosed
          without your explicit consent unless required
          by law.
        </label>
    </div>
    <div *ngIf="isSubmittedConsent && !isChecked" style="margin-left: 18px;">
        <span class="text-danger"> Consent
            Required
        </span>
    </div>
    <p-footer>
        <button type="button" [ngClass]="{'disabled-btn': !isChecked}" (click)="hideConsentModal()" class="btn btn-primary" [disabled]="!isChecked" data-dismiss="modal">Confirm</button>
    </p-footer>
</p-dialog>
