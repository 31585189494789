import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { BaseResponse } from '../../../models/response';
import { ToasterService } from '../../../shared/services/toaster.service';
import { environment } from 'src/environments/environment';
import { RecaptchaComponent } from 'ng-recaptcha';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {


  signupForm: FormGroup;
  isSubmitted: boolean;
  isDisabled: boolean = false;
  captchaString: string = '';
  captchaResponse: string = '';
  count: number = 0;
  recaptchaSiteKey: string = environment.recaptcha.siteKey;
  @ViewChild('invisible') invisibleCaptcha: RecaptchaComponent;

  constructor(
    private fBuild: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService
  ) {
  }

  ngOnInit() {
    this.formInitialization();
  }

  formInitialization() {
    this.signupForm = this.fBuild.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(new RegExp('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,16}$'))]],
      consentGiven: [false, [Validators.requiredTrue]],
      // captcha: ['', [Validators.required]]
    });

    this.generateCaptcha();
  }
  get f() { return this.signupForm.controls; }

  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  login() {
    this.router.navigate(['login']);
  }

  signup() {
    this.isSubmitted = true;
    if (this.signupForm.valid) {
      this.isDisabled = true;
      this.invisibleCaptcha.execute();
    }
  }

  proceedForSignUp() {
    this.authService
      .signup(this.signupForm.value)
      .subscribe((response: BaseResponse) => {
        this.isDisabled = false;
        if (response.status == "SUCCESS") {
          this.toaster.showSuccess("Registration Successful!");
          this.router.navigate(["thankyou"]);
        } else {
          this.toaster.showError("Regsistration Failed..! " + response.message);
        }
        this.invisibleCaptcha.reset();
      });
  }

  generateCaptcha(): void {
    this.captchaString = this.authService.generateCaptcha(6);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse) {
      this.proceedForSignUp(); // Call signup directly after captcha resolved
    }
  }

}
